@font-face {
  font-family: 'GTAmericaRegular';
  src: url(../public/font/GTAmericaRegular.ttf);
  font-display: swap;
}

@font-face {
  font-family: 'Sharp_Grotesk_22_Medium';
  src: url(../public/font/SharpGroteskMedium22-Regular.ttf);
  font-display: swap;
}
@font-face {
  font-family: 'QuireSansLight';
  src: url(../public/font/QuireSansLight.ttf);
  font-display: swap;
}

.ant-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 20px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0px;
  margin-top: 10px;
}

body {
  background-color: #f2f2f5;
}

a {
  color: #000;
}

a:hover {
  color: #000;
}

.ant-table-thead > tr > th {
  background-color: #ebf5ff;
}

.ant-table-thead > tr > th:hover {
  background-color: #ebf5ff !important;
}

.ant-table-thead > tr > th:first-child {
  border-radius: 2rem 0 0 2rem !important;
  padding: 1rem;
  padding-left: 3rem;
}

.ant-table-thead > tr > th:last-child {
  border-radius: 0 2rem 2rem 0 !important;
}

.ant-table-tbody > tr > td {
  padding: 35px 16px;
}

.ant-table-tbody > tr:first-child > td {
  padding: 8px 16px;
}

.ant-table table {
  border-radius: 2px 2px 0 0;
  padding: 1rem 2rem;
}

.ant-table,
.ant-table-scroll-horizontal {
  border-radius: 2.5rem;
}

input[type='checkbox'] {
  display: none;
}

.ant-input-number.input-request {
  border-radius: 10px;
}

.input-request .ant-input-number-group-addon:first-child {
  border-radius: 10px 0px 0px 10px;
}

.wrap-collabsible {
  margin: 1.2rem 0;
  display: flex;
  flex-flow: row wrap-reverse;
}

.background-container {
  opacity: 0.9;
}

.custom-checkbox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
}

.custom-checkbox .ant-checkbox-inner {
  border-color: #e27253;
  border: solid 2px #e27253;
}

.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #e27253;
  border-color: #e27253;
}

.custom-checkbox .ant-checkbox-checked::after {
  border-color: #e27253;
}

.highlight {
  background-color: #609bfa3a;
  transition: background-color 0.5s ease;
  border-radius: 10px;
}

.PhoneInputInput {
  height: 44px;
  width: 184px;
  background-color: #fcfcfc;
  outline: none;
  font-weight: bold;
}

.lbl-toggle {
  background: #f3f4f6;
}

.lbl-toggle::before {
  content: 'Open';
}

.toggle:checked + .lbl-toggle::before {
  content: 'Collapse';
}

.collapsible-content {
  max-height: 0px;
  overflow-y: scroll;
  transition: max-height 0.25s ease-in-out;
}

.toggle:checked + .lbl-toggle + .collapsible-content {
  max-height: 350px;
  width: 100%;
}

.toggle:checked + .lbl-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.collapsible-content p {
  margin-bottom: 0;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 2%;
}

input[type='radio'] {
  accent-color: black;
}

.fontSharpGrotesk22Medium {
  font-family: 'Sharp_Grotesk_22_Medium';
  font-display: swap;
}

.fontGTAmericanR {
  font-family: 'GTAmericaRegular';
  font-display: swap;
}

.fontQuireSansLight {
  font-family: 'QuireSansLight';
  font-display: swap;
}

.container__grid__image {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: 22px;
  justify-content: center;
  align-items: start;
  position: relative;
  grid-area: image;
  width: 100%;
  height: 200px;
  object-fit: contain;
}

.ant-table-thead > tr > th {
  font-weight: bold;
  font-size: 0.8rem;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

/* .ant-table-column-sort ::after {
  background-color: #ebf5ff !important;
} */

/* .ant-table-thead > tr > th::after {
  color: red !important;
} */

thead tr th::before {
  border-radius: 50px 0 0 30px;
}
thead tr th {
  background-color: #ebf5ff !important;
}

.ant-table-column-sorter-inner {
  position: absolute;
  z-index: 20;
}

.ant-table-column-sorters {
  display: flex;
  align-items: stretch;
  margin-right: 20px;
}

.MuiPaper-root {
  z-index: 20;
}

.operationsCardsStyles {
  padding: 2rem 1.75rem;
  border-radius: 1.5rem;
  background-color: rgb(255 255 255);
  margin-bottom: 1.5rem;
}

.operationsCardsStyles h2 {
  font-weight: 600;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .ant-picker-panels {
    display: block !important;
    position: absolute !important;
    background-color: white;
    left: -149px;
    top: -4px;
  }
}

.ant-picker-range {
  border-radius: 10px;
  border: none;
}

.truncate-text-1-lines {
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Número de líneas */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 2.3;
}

.truncate-text {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Número de líneas */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.3;
}

.truncate-text-3-lines {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Número de líneas */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.3;
}

.MuiSelect-select {
  font-size: 12px !important;
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ant-modal-header {
  border-radius: 20px 20px 0px 0px;
}

.ant-modal-content {
  border-radius: 20px;
}

.ant-modal-title {
  text-align: center;
}
